import React, { Fragment } from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ProjectHeader from '../components/ProjectHeader'
import ProjectDescription from '../components/ProjectDescription'
import ProjectComingSoon from '../components/ProjectComingSoon'
import ProjectNextLink from '../components/ProjectNextLink'
import InstagramFeed from '../components/InstagramFeed'
import ContactBoxes from '../components/ContactBoxes'
import Footer from '../components/Footer'

import headerRightBanner from '../images/projects/apartamento-306-sw/header-right-banner.jpg'

import '../styles/global.scss'

const dynamicInfos = {
  headerImage: headerRightBanner,
  title: 'Apartamento',
  subTitle: '306sw',
  typeService: 'PROJETO + OBRA',
  duration: 'andamento',
  deliverDate: '2019',
  local: 'Brasília',
  color: '#ffbf68',
  description: (
    <Fragment>
      <p>
        Estamos fazendo os últimos ajustes para trazer este projeto para vocês.
        Aguardem, em breve atualizaremos este projeto.
      </p>
    </Fragment>
  ),
  nextProject: 'apartamento-104-nw'
}

const IndexPage = () => (
  <Layout>
    <SEO
      title="Apartamento 306 SW"
      keywords={[`gatsby`, `application`, `react`]}
    />
    <ProjectHeader
      headerImage={dynamicInfos.headerImage}
      title={dynamicInfos.title}
      subTitle={dynamicInfos.subTitle}
    />
    <ProjectDescription
      typeService={dynamicInfos.typeService}
      duration={dynamicInfos.duration}
      deliverDate={dynamicInfos.deliverDate}
      local={dynamicInfos.local}
      description={dynamicInfos.description}
      color={dynamicInfos.color}
    />
    <ProjectComingSoon color={dynamicInfos.color} />
    <ProjectNextLink nextProject={dynamicInfos.nextProject} />
    <InstagramFeed />
    <ContactBoxes />
    <Footer />
  </Layout>
)

export default IndexPage
